<template>
  <div class="mt-2">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3" v-if="isPhisical">
                      <base-input
                        :name="beForm[rep].attribute_NAME_value.label"
                        vid="attribute_NAME_value"
                        :label="beForm[rep].attribute_NAME_value.label"
                        v-model="form[rep].attribute_NAME_value"
                        placeholder="Inserisci un nome"
                        :rules="{ required: true }"
                      />
                    </div>
                    <div class="form-group col-md-3" v-if="isPhisical">
                      <base-input
                        :name="beForm[rep].attribute_SURN_value.label"
                        vid="attribute_SURN_value"
                        :label="beForm[rep].attribute_SURN_value.label"
                        v-model="form[rep].attribute_SURN_value"
                        placeholder="Inserisci un cognome"
                        :rules="{ required: true }"
                      />
                    </div>
                    <div class="form-group col-md-3" v-if="!isPhisical">
                      <base-input
                        :name="beForm[rep].attribute_CNAM_value.label"
                        vid="attribute_CNAM_value"
                        :label="beForm[rep].attribute_CNAM_value.label"
                        v-model="form[rep].attribute_CNAM_value"
                        placeholder="Inserisci una ragione sociale"
                        :rules="{ required: true }"
                      />
                    </div>
                    <div class="form-group col-md-3" v-if="!isPhisical">
                      <base-input
                        name="p_iva"
                        vid="attribute_CREG_value"
                        label="Partita IVA"
                        v-model="form[rep].attribute_CREG_value"
                        placeholder="Inserisci una partita IVA"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].attribute_NINO_value.label"
                        vid="attribute_NINO_value"
                        :label="beForm[rep].attribute_NINO_value.label"
                        v-model="form[rep].attribute_NINO_value"
                        textcase="upper"
                        placeholder="Inserisci un codice fiscale"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="attribute_CLI_value"
                        :name="beForm[rep].attribute_CLI_value.label"
                        :label="beForm[rep].attribute_CLI_value.label"
                        v-model="form[rep].attribute_CLI_value"
                        :options="
                          Object.values(
                            beForm[rep].attribute_CLI_value.options
                          ).map((o) => {
                            return { value: o.value, text: o.text };
                          })
                        "
                        :rules="getRules('attribute_SEEX_value')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="attribute_SUP_value"
                        :name="beForm[rep].attribute_SUP_value.label"
                        :label="beForm[rep].attribute_SUP_value.label"
                        v-model="form[rep].attribute_SUP_value"
                        :options="
                          Object.values(
                            beForm[rep].attribute_SUP_value.options
                          ).map((o) => {
                            return { value: o.value, text: o.text };
                          })
                        "
                        :rules="getRules('attribute_SUP_value')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <address-book-form
                v-model="address_book_form"
                :beECForm="beForm[addrBook]"
                :repository="addrBook"
              ></address-book-form>
              <correspondence-form
                v-model="correspondence_form"
                :beECForm="beForm[cor]"
                :repository="cor"
              >
              </correspondence-form>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import AddressBookForm from "@/components/form/AddressBookForm";
import CorrespondenceForm from "@/components/form/CorrespondenceForm";
import AddressBookCorrespondenceMixin from "@/mixins/AddressBookCorrespondenceMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import ShowMixin from "@/mixins/FormMixin";
import { mapGetters } from "vuex";

export default {
  mixins: [FormMixin, ShortcutMixin, ShowMixin, AddressBookCorrespondenceMixin],
  data() {
    return {
      repository: "agency_contact",
      id: this.$route.params.id,
      item: this.$route.params.item,
      correspondence_id: null,
      address_book_id: null,
      form: {
        defaultCountryName: "Italia",
        agency_contact: {
          home_province: null,
          optHomeCountries: [],
          optHomeProvinces: [],
          optHomeCouncils: [],
          status_agencycontact: 0,
          attribute_NAME_value: null,
          attribute_SURN_value: null,
          attribute_CNAM_value: null,
          attribute_CREG_value: null,
          attribute_NINO_value: null,
          attribute_CLI_value: null,
          attribute_SUP_value: null,
        },
        address_book: {
          status_addressbook: 0,
          attribute_CELL_value: null,
          attribute_FAX_value: null,
          attribute_LPN_value: null,
          attribute_MAIL_value: null,
          attribute_PEC_value: null,
          attribute_PHON_value: null,
          attribute_WEB_value: null,
          agency_contacts: {},
        },
        correspondence: {
          status_correspondence: 0,
          attribute_ADDR_value: null,
          attribute_CITY_value: null,
          attribute_CITY2_value: null,
          attribute_NOR_value: null,
          attribute_NAME2_value: null,
          attribute_ZIPC_value: null,
          agency_contacts: {},
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseSelect,
    AddressBookForm,
    CorrespondenceForm,
  },
  methods: {
    onSubmit() {
      switch (this.isPhisical) {
        case true:
          this.$delete(this.form[this.repository], "attribute_CREG_value");
          this.$delete(this.form[this.repository], "attribute_CNAM_value");
          break;
        case false:
          this.$delete(this.form[this.repository], "attribute_SURN_value");
          this.$delete(this.form[this.repository], "attribute_NAME_value");
          break;
      }
      this.isLoading = true;
      this.update(this.repository, this.id)
        .then(() => {
          let promises = [];
          if (this.address_book_id) {
            promises.push(
              this.editSection(
                this.addrBook,
                this.address_book_id,
                this.repository
              )
            );
          } else {
            promises.push(
              this.storeSection(this.addrBook, this.id, this.repository)
            );
          }
          if (this.correspondence_id) {
            promises.push(
              this.editSection(
                this.cor,
                this.correspondence_id,
                this.repository
              )
            );
          } else {
            promises.push(
              this.storeSection(this.cor, this.id, this.repository)
            );
          }
          Promise.all(promises)
            .then(() => {
              this.onSuccess("contatto");
              this.shortcut(
                "module.PFOLIO",
                null,
                "#Contacts",
                "filterContact"
              );
              this.isLoading = false;
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        })
        .catch((error) => {
          this.onFailure(error);
        });
    },
    onSuccess(resource) {
      this.$showSnackbar({
        preset: "success",
        text: `Azione completata: ${resource} aggiornato`,
      });
    },
    onFailure(error) {
      let errMsg = this.$getErrorMessage(error);
      this.$showSnackbar({
        preset: "error",
        text: `${errMsg}`,
      });
      this.isLoading = false;
    },
    ...mapGetters("auth", [
      "countries",
      "provinces",
      "provinceByProvinceId",
      "countries",
      "provinces",
      "councils",
    ]),
  },
  beforeMount() {
    this.isLoading = true;
    this.fetchEditForm(this.repository, this.id).then(() => {
      let promises = [];
      promises.push(this.fetchAddressBook());
      promises.push(this.fetchCorrespondences());
      Promise.all(promises)
        .then(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          this.onFailure(error);
        });
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
    cor() {
      return "correspondence";
    },
    addrBook() {
      return "address_book";
    },
    isPhisical() {
      return (
        !this.form[this.rep].attribute_CREG_value &&
        !this.form[this.rep].attribute_CNAM_value
      );
    },
  },
};
</script>
